import React,{ useState,useEffect } from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';
import { Buffer } from "buffer";
import architectureImg from './images/EdgeConfigurator-Page-1.drawio.png';
import agentImg from './images/EdgeConfigurator-Page-2.drawio.png';
// import { socket } from './socket';
import ReviewSpecsEditor from './reviewspecs';
import Linkify from 'linkify-react';

import {
    CDN_FINDER_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    Table,
    Header,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Alert,
    Input,
    StatusIndicator,
    ExpandableSection,
    FormField,
    Select,
    CopyToClipboard,
    Grid,
    Textarea,
    TextContent,
    ProgressBar,
    Wizard,
    Checkbox,
    Icon,
} from '@cloudscape-design/components';
import Avatar from "@cloudscape-design/chat-components/avatar";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import Badge from "@cloudscape-design/components/badge";

// import typescriptHighlight from "@cloudscape-design/code-view/highlight/typescript";
import {CodeView} from "@cloudscape-design/code-view";

import '../../styles/form.scss';
import SynopsisPanelV3 from '../SynopsisPanelV3';
import { sendAnalytics, sendError } from '../../resources/rum-provider';
// import { Code } from 'react-feather';
import {SPECS} from './specstemplate.jsx';
import { io } from 'socket.io-client';
import {
    EDGE_CONFIGURATOR_ENDPOINT
} from '../../resources/prod-env.jsx';

const demoModule = "Edge Configurator";
const demoType = "tool";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class EdgeConfigurator extends React.Component {

    constructor(props) {
        super(props);
        // console.log(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        // this.onFollowQuestion = this.onFollowQuestion.bind(this);
        this.onConnect = this.onConnect.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
        this.onError = this.onError.bind(this);
        // this.onQuestion = this.onQuestion.bind(this);
        this.dataProvider = new DataProvider();
        // this.socket = socket;
        this.createPromise = this.createPromise.bind(this);
        this.resolver = null;
        this.addMessage = this.addMessage.bind(this);
        this.setActiveStep = this.setActiveStep.bind(this);
        this.handleSpecs = this.handleSpecs.bind(this);
        this.handleTemplate = this.handleTemplate.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.addCompletion = this.addCompletion.bind(this);

        // this.addLogs = this.addLogs.bind(this);

        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            q:"what are the best practices for static delivery?",
            status: "takes about a minute",
            statusType: "info",
            contentTypes: [],
            k:{label: "20",value: "20" },
            lang:{label: "English",value: "en" },
            authKey:'',
            userId:'',
            // connected:socket.connected,
            messages:[],gmessages:[],vmessages:[],
            logs:[],glogs:[],
            firstTime:true,gFirstTime:true,vFirstTime:true,
            currentProgress:0,
            currentProgressMsg:'',
            activeStepIndex:0,
            specs:SPECS,
            template:'',
            vtemplate:'',
            userScrolled:false,
            verbose:true,
            stage:'specs',
            // user:props.user,
        };
        let user = props.user;
        // console.log(user);
        if(user){
            let key = user.keyPrefix+"."+user.username+".accessToken";
            // console.log(key);
            let authKey = user.storage[key];
            this.state.authKey = authKey;
            this.state.userId = user.storage['userId'];
            // console.log(authKey);
        }

        this.socket = io(EDGE_CONFIGURATOR_ENDPOINT,{
            transports:['websocket'],
            // transports: ['polling'],
            autoConnect:false,
            reconnection:false,
            extraHeaders:{'Authorization':this.state.authKey},
            query: {
                token: this.state.authKey
              }
        });

        this.socket.on('connect', this.onConnect);
        this.socket.on('disconnect', this.onDisconnect);
        this.socket.on('connect_error', err => this.onError(err));
        this.socket.on('connect_failed', err => this.onError(err));        

        this.socket.on('question', (value, callback) => {
            // console.log(value);
            // console.log(callback);
            this.addMessage('bot','question',`Q:${value?.data}`);
            const [ promise, resolve ] = this.createPromise();
            this.resolver = { resolve };
            // console.log("Promise",promise);
            if(callback){
                return promise.then((value)=>{
                    this.setState({'currentProgressMsg':'thinking..'});
                    // console.log("Returning value ",value);
                    callback(value);
                });          
            }  
        });

        this.socket.on('iteration', (value) => {
            this.setState({'iteration':value});
        });

        this.socket.on('status', (value) => {
            this.setState({'currentProgressMsg':value});
        });

        this.socket.on('log', (value) => {
            // console.log(callback);
            this.addMessage('bot','log',value);
            // this.addLogs(value);
        });
        this.socket.on('error', (value) => {
            // console.log(callback);
            this.addMessage('bot','error',value);
            // this.addLogs(value);
        });
        this.socket.on('explain', (value) => {
            // console.log(callback);
            this.addMessage('bot','explain',`Explain:${value}`);
            // this.addLogs(value);
        });
        // this.socket.on('explain', (value, callback) => {
        //     // console.log(value);
        //     // console.log(callback);
        //     this.addMessage('bot','explain',`E:${value?.data}`);
        //     const [ promise, resolve ] = this.createPromise();
        //     this.resolver = { resolve };
        //     // console.log("Promise",promise);
        //     if(callback){
        //         return promise.then((value)=>{
        //             // console.log("Returning value ",value);
        //             callback(value);
        //         });          
        //     }  
        // });

        this.socket.on('summary', (value) => {
            // console.log(callback);
            this.addMessage('bot','summary',value);
            // this.addLogs(value);
        });
        this.socket.on('specs', (value) => {
            // console.log(callback);
            // this.addMessage('bot','specs',value);
            this.setState({'specs':value,'activeStepIndex':1});
            this.addCompletion({
                "stage":"specs",
                "specs":value,
                "template":'',
                "vtemplate":''
            });

            // this.addLogs(value);
        });
        this.socket.on('template', (value) => {
            // console.log(callback);
            // this.addMessage('bot','template',value);
            this.setState({'template':value,'activeStepIndex':3});
            this.addCompletion({
                "stage":"template",
                "specs":this.state.specs,
                "template":value,
                "vtemplate":''
            });
            // this.addLogs(value);
        });
        this.socket.on('vtemplate', (value) => {
            // console.log(callback);
            // this.addMessage('bot','template',value);
            this.setState({'vtemplate':value,'activeStepIndex':5});
            this.addCompletion({
                "stage":"vtemplate",
                "specs":this.state.specs,
                "template":this.state.template,
                "vtemplate":value
            });
        });

        this.state['connected'] = this.socket.connected;
    }

    addCompletion(payload){
        payload["userId"] = localStorage.getItem("userId");
        this.socket.emit('completion', payload);
    }
    
    addMessage(user,type,msg){
        if(type ==='log' && this.state.activeStepIndex === 0){
            // console.log("Skipping log for now");
            if(!this.state.verbose && msg.match(/\Thought:(.*)$/gm)){
                // return;
            }
            // if(!msg.match(/\Thought:(.*)$/gm)){
            //     type = 'explain';
            // }
        }

        let messageID = '';
        if(this.state.stage === 'specs')
            messageID = 'messages';
        
        if(this.state.stage === 'generate')
            messageID = 'gmessages';

        if(this.state.stage === 'validate')
            messageID = 'vmessages';

        let messages = this.state[messageID];
        messages.push({'user':user,'msg':msg,'type':type});
        // console.log("Setting messages %s,%j",messageID,messages);        
        var arr = msg.match(/\Action:(.*)$/gm) || [""];
        // console.log(arr);
        this.setState({[messageID]:messages,'currentProgressMsg':arr[0]});
    }

    createPromise = () => {
        let resolver;
        return [ new Promise(( resolve, reject ) => {
            resolver = resolve;
            this.setState({'currentProgressMsg':'awaiting answer'});
        }), resolver]
    }

    onConnect() {
        console.log("Connected");
        this.setState({ 'connected': true,'currentProgressMsg':'connected'});
    }
  
    onDisconnect() {
        console.log("Disconnected");
        this.setState({ 'connected': false,'currentProgressMsg':'disconnected'});
    }

    onError(err){
        console.log("Error while connecting");
        this.addMessage('bot','log','Access denied!');
        // console.log(err);
    }

    setActiveStep(detail){
        let currentProgress = detail.requestedStepIndex *10;
        this.setState({'activeStepIndex':detail.requestedStepIndex,'currentProgress':currentProgress});
        if(detail.requestedStepIndex === 2){
            this.handleGenerate();
            this.setState({"stage":"generate"});
        }
        if(detail.requestedStepIndex === 4){
            this.handleValidate();
            this.setState({"stage":"validate"});
        }
    }

    onInputChange(name, e) {
        e.preventDefault();
        let value = null;
        if(name === 'currentAnswer'){
            value = e.detail.value;
        }
        else if(name === 'verbose'){
            value = e.detail.checked;
            console.log("Checked :",value);
        }
        else{
            value = e.detail.selectedOption;
        }
        this.setState({ [name]: value});
    }

    // onFollowQuestion(e){
    //     console.log("In onFollowQuestion ",e);
    // }

    handleClick(e) {
        // console.log("In handleClick ",e);

        e.preventDefault();
        if (!this.state.currentAnswer) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        let currentAnswer = this.state.currentAnswer;

        this.setState({
            status: "in progress, may take upto a minute..",
            statusType: "loading"
        });

        this.addMessage('user','answer',currentAnswer);
        
        this.resolver?.resolve(currentAnswer);
        // this.socket.on('start',emit('start', {"data":"Initialize"});)
        // note usage analytics is recorded only once per session
        if(this.state.firstTime){
            console.log("First time");
            this.socket.emit('start', {"data":currentAnswer});
            this.setState({'firstTime':false});
            sendAnalytics({ demo: demoModule });
        }

        this.setState({'currentAnswer':'','stage':'specs'});
    }

    handleGenerate(e) {
        // console.log("In handleGenerate ",e);

        // e.preventDefault();
        if (!this.state.specs && this.state.gFirstTime) {
            console.log("Specs not defined");
            this.setState({ alert: true });
            return;
        }
        else if(!this.state.gCurrentAnswer && !this.state.gFirstTime){
            console.log("GCurrent answer not defined");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        sendAnalytics({ demo: demoModule });
        let currentAnswer = this.state.gCurrentAnswer;

        this.setState({
            status: "in progress, may take upto a minute..",
            statusType: "loading"
        });
        
        this.resolver?.resolve(currentAnswer);

        if(this.state.gFirstTime){
            // console.log("GFirst time");
            this.socket.emit('generate', {"data":this.state.specs});
            this.setState({'gFirstTime':false});
        }
        else{
            this.addMessage('user','answer',currentAnswer);
        }

        this.setState({'gCurrentAnswer':''});
    }

    handleValidate(e) {
        // console.log("In handleGenerate ",e);

        // e.preventDefault();
        if (!this.state.template && this.state.vFirstTime) {
            console.log("Template not defined");
            this.setState({ alert: true });
            return;
        }
        else if(!this.state.vCurrentAnswer && !this.state.vFirstTime){
            console.log("GCurrent answer not defined");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        sendAnalytics({ demo: demoModule });
        let currentAnswer = this.state.vCurrentAnswer;

        this.setState({
            status: "in progress, may take upto a minute..",
            statusType: "loading"
        });
        
        this.resolver?.resolve(currentAnswer);

        if(this.state.vFirstTime){
            // console.log("GFirst time");
            this.socket.emit('validate', {"data":this.state.template});
            this.setState({'vFirstTime':false});
        }
        else{
            this.addMessage('user','answer',currentAnswer);
        }

        this.setState({'vCurrentAnswer':''});
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }

    handleReset() {
        console.log("In handleReset");
        this.socket.disconnect();
        this.socket.connect();
        this.setState({'gFirstTime':true,'firstTime':true});
    }

    handleClear() {
        console.log("In handleClear");
        this.setState({'gFirstTime':true,'firstTime':true,
        'messages':[],'gmessages':[],'vmessages':[],
        'specs':'','template':'','vtemplate':''});
    }

    handleSpecs(e) {
        // console.log("In handleSpecs",e.detail.value);
        this.setState({"specs":e.detail.value});
    }

    handleTemplate(e) {
        // console.log("In handleSpecs",e.detail.value);
        this.setState({"template":e.detail.value});
    }

    handleScroll(e) {
        // console.log('User scrolled!', e);
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        // console.log("Is bottom ",bottom);
        this.setState({'userScrolled':!bottom});
    }

    componentDidCatch(error, info) {
        console.log(error);
        sendError(error);
        // cwr('recordError', error);
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
        // this.dataProvider.listContentTypes({"Authorization":this.state.authKey}, response => this.setState({ contentTypes: response }));
        // this.dataProvider.listQuestions({"Authorization":this.state.authKey}, response => this.setState({ questions: response }));
        this.socket.connect();
        // this.socket.timeout(5000).emit('start', {"data":"Initialize"});
        // , (value) => {
        //     this.onQuestion(value);
        //   });
    }

    componentWillUnmount(){
        console.log("In componentWillUnmount ");
        let events = ['question','connect','disconnect','log','explain','summary','specs','template'];
        events.forEach(event => {
            this.socket.off(event);
        });

        // this.socket.off('question');
        // this.socket.off('connect');
        // this.socket.off('disconnect');
        // this.socket.off('log');
        // this.socket.off('explain');
        // this.socket.off('summary');
        // this.socket.off('specs');
        // this.socket.off('template');
    }

    componentDidUpdate() {
        // I was not using an li but may work to keep your div scrolled to the bottom as li's are getting pushed to the div
        if(!this.state.userScrolled){
            const objDiv = document.getElementById('messages');
            if(objDiv){
                objDiv.scrollTop = objDiv.scrollHeight;
            }
            const gobjDiv = document.getElementById('gmessages');
            if(gobjDiv){
                gobjDiv.scrollTop = gobjDiv.scrollHeight;
            }
            const vobjDiv = document.getElementById('vmessages');
            if(vobjDiv){
                vobjDiv.scrollTop = vobjDiv.scrollHeight;
            }

        }
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            onFollowQuestion={this.onFollowQuestion}
                            handleReset={this.handleReset}
                            handleClear={this.handleClear}
                            setActiveStep={this.setActiveStep}
                            currentState={this.state}
                            handleSpecs={this.handleSpecs}
                            handleTemplate={this.handleTemplate}
                            handleGenerate={this.handleGenerate}
                            handleScroll={this.handleScroll}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='xxxs'>
                <SynopsisPanelV3 label="Synopsis" architectureImgs={[architectureImg,agentImg]} videolink="https://broadcast.amazon.com/videos/1290218?seek=00h33m03s" demotype={demoType}>
                The experimental tool aims to simplify the process of configuring Amazon CloudFront for workloads. It achieves this by interactively capturing the application’s content delivery requirements and automatically generating an initial CloudFormation template. This approach offers benefits such as efficient requirements gathering, automated template generation, reduced errors and iterations, and accelerated deployment. By providing a guided and automated approach, the tool aims to make CloudFront configuration more accessible, efficient, and user-friendly, enhancing the overall experience of deploying content delivery solutions on AWS.
                More details here, leave your test results as well.
                https://quip-amazon.com/hJKTAs0dHdvJ/Experimental-Edge-Configurator-PoC-Generator
                </SynopsisPanelV3>
            <Wizard 
            i18nStrings={{
                stepNumberLabel: stepNumber =>
                `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) =>
                `Skip to ${step.title}`,
                navigationAriaLabel: "Steps",
                // cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                // submitButton: "Generate template",
                optional: "optional"
            }}
            onNavigate={({ detail }) => props.setActiveStep(detail)}
            activeStepIndex={props.currentState.activeStepIndex}
            secondaryActions={
            <Box
              margin={{ bottom: "xs", left: "l" }}
              color="text-body-secondary">Status: {props.currentState.currentProgressMsg}
                          <Box variant='awsui-gen-ai-label'>iterations left {props.currentState['iteration']}</Box>

                <LoadingBar variant="gen-ai" />
            </Box>
            }
            allowSkipTo
            steps={[
                {
                  title: "Gather requirements",
                  description:
                    "Here you interactive specificy your application's delivery and security requirements. If you have the requirmeents in markdown format you can skip this step.",
                  content: (
                    <ChatPanel messages={props.currentState.messages} onInputChange={props.onInputChange} 
                    handleClick={props.handleClick} 
                    currentState={props.currentState} handleScroll={props.handleScroll}/>
                  ),
                  isOptional: true
                },
                {
                    title: "Review requirements",
                    description:
                    "Here you review the gathered requirements from previous step. Make edits to capture any missing information. Alternatvely, you can edit below sample template to match your specific requirements and proceed from here...",
                    content: (
                      <Container>
                        <ReviewSpecsEditor specs={props.currentState.specs} handleSpecs={props.handleSpecs}/>
                        </Container>
                    ),
                    isOptional: true
                },
                {
                    title: "Generate template",
                    description:
                    "This step showcases the different actions undertaken by the agent. Pay attention to how the agent augments its knowing with documentation, validates the template and fixes errors found during validation.",
                    content: (
                        <GChatPanel messages={props.currentState.gmessages} onInputChange={props.onInputChange} 
                                            handleGenerate={props.handleGenerate}
                                            currentState={props.currentState} handleScroll={props.handleScroll}/>
                    ),
                    isOptional: true
                },
                {
                    title: "Review template",
                    description:
                    "View the CloudFormation template if all went well. Note while the tool tries it's best to provide a fully ready CloudFormation template, it will not be accurate/complete and needs to be modified.",
                    content: (
                      <Container>
                        <ReviewSpecsEditor specs={props.currentState.template} handleSpecs={props.handleTemplate}/>
                        </Container>
                    ),
                    isOptional: false
                },
                // {
                //     title: "Validate template",
                //     description:
                //     "This step showcases the different validation actions undertaken by the agent. Pay attention to how the agent augments its knowing with documentation, validates the template and fixes errors found during validation.",
                //     content: (
                //         <VChatPanel messages={props.currentState.vmessages} onInputChange={props.onInputChange} 
                //                             handleValidate={props.handleValidate} 
                //                             currentState={props.currentState} handleScroll={props.handleScroll}/>
                //     ),
                //     isOptional: false
                // },
                // {
                //     title: "Final template",
                //     description:
                //     "View the CloudFormation template if all went well. Note while the tool tries it's best to provide a fully ready CloudFormation template, it will not be accurate/complete and needs to be modified.",
                //     content: (
                //       <Container>
                //         <ReviewSpecsEditor specs={props.currentState.vtemplate}/>
                //         </Container>
                //     ),
                //     isOptional: false
                // },

                ]}
                />
                <SpaceBetween direction='horizontal' size='xxxs'>
                    <Button variant="link" onClick={props.handleReset}>Reset</Button>
                    <Button variant="link" onClick={props.handleClear}>Clear</Button>   
                </SpaceBetween>
                <Box>Note: This tool is currently experimental and the output should be thorougly vetted and corrected for errors</Box>  
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on this tool?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

const Message1 = ({message,key}) => {
    return(
    message.user === 'bot'?
        <SpaceBetween direction='horizontal' size='xs'>
            {1==0 && <CodeView key={key} variant='awsui-gen-ai-label' content={message.msg} actions={
                (message.type === 'specs' || message.type === 'template')?
                    <CopyToClipboard
                    copyButtonAriaLabel="Copy code"
                    copyErrorText="Code failed to copy"
                    copySuccessText="Code copied"
                    textToCopy={message.msg}/>:''}/>}
            <Box variant='samp' >
            <Avatar key={key} 
            ariaLabel="Avatar of Edge Configuration AI assistant"
            color="gen-ai"
            iconName="gen-ai"
            tooltipText="Edge Configuration Assistant"/>
 
                {message.msg}
            </Box>
        </SpaceBetween>:
        <SpaceBetween direction='horizontal' size='xs'>
            <Avatar key={key}
                ariaLabel="You"
                tooltipText="You"/>
                <Box key={key}>{message.msg}</Box>
        </SpaceBetween>
    )
};


const CAvatar = ({message,key}) => {
    if(message.user === 'bot'){
        if(message.type === 'question'){
            return(
                <SpaceBetween direction='vertical' size='xxxs'>
                    <Icon name="gen-ai" alt='Edge Configuration AI assistant'/>                
                </SpaceBetween>
            );
        }
        return (
            <Icon name="gen-ai" alt='Edge Configuration AI assistant'/>
        );
    }
    else{
        return (<Icon name="user-profile" alt='You'/>);
    }
}

const renderLink = ({ attributes, content }) => {
    const { href, ...props } = attributes;
    // console.log(href);
    return <Link external href={href} {...props}>{content}</Link>;
  };
  
const CMsg = ({message,key}) => {
    // const [displayedContent, setDisplayedContent] = useState("");
    // const [index, setIndex] = useState(0);
    // useEffect(() => {
    //     /*Create a new setInterval and store its id*/
    //     if(!message['viewed']){
    //     const animKey = setInterval(() => {
    //       setIndex((index) => {
    //         /*This setState function will set the index
    //         to index+1 if there is more content otherwise
    //         it will destory this animation*/
          
    //         if (index >= message['msg'].length - 1) {
    //           clearInterval(animKey);
    //           message['viewed'] = true;
    //           return index;
    //         }
    //         return index + 1;
    //       });
    //     }, 20);
    //     }
    //   }, []);
    
    //   useEffect(() => {
    //     if(!message['viewed']){
    //         setDisplayedContent((displayedContent)=>displayedContent + message['msg'][index]);
    //     }
    //     else{
    //         setDisplayedContent(message['msg']);
    //     }
    //   }, [index])

    return (
    message.user === 'bot'?
    <div className="chat-msg">
            <CodeView key={key} content={<Linkify options={{ render: renderLink }}>{message.msg}</Linkify>} actions={message.type === 'question1'?<Icon name="flag" alt="Question" variant='subtle'/>:""}/>
    </div>
    :<Box key={key} variant='samp'>{message.msg}</Box>);
}

const Message = ({message,key}) => {

    return(
        <div
            style={{
              display: "flex",
              flexDirection:'inherit',
              alignItems: "top",
              gap:5,
            }}
          >
            <CAvatar key={key} message={message}/>

            <CMsg key={key} message={message}/>
          </div>
    );
}

const ChatPanel = props => {

    return (
        <Container footer={
            <Grid gridDefinition={[
                { colspan: 9 }, { colspan: 3 },
            ]}>
            <div>
                <Textarea onChange={props.onInputChange.bind(this, 'currentAnswer')} 
                value={props.currentState.currentAnswer} rows={4} autoFocus 
                placeholder="Answer here"/></div>
            <div>
                <SpaceBetween direction='vertical'>
                    <SpaceBetween direction='horizontal' size='m'>
                        <Button variant="primary" onClick={props.handleClick}>Answer</Button>
                        {/* <Checkbox onChange={props.onInputChange.bind(this, 'verbose')} 
                            checked={props.currentState['verbose']}>verbose</Checkbox> */}
                    </SpaceBetween>
                </SpaceBetween>
            </div>
            </Grid>
        }>
        <div id="messages" style={{ height: 400 + 'px', overflow: 'auto', padding: 0 + 'px' }} onScroll={props.handleScroll}>
            {/* <Messages messages={props.messages}/> */}
            <SpaceBetween direction='vertical' size='xs'>
                {props.currentState.verbose && props.messages?.map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
                {!props.currentState.verbose && props.messages?.filter(message => message.type !='log').map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
            </SpaceBetween>
        </div>
    </Container>
    );
}

const GChatPanel = props => {
    return (
        <Container 
        // footer={
        //     <Grid gridDefinition={[
        //         { colspan: 9 }, { colspan: 3 },
        //     ]}>
        //     <div>
        //         <Textarea onChange={props.onInputChange.bind(this, 'gCurrentAnswer')} 
        //         value={props.currentState.gCurrentAnswer} rows={4} autoFocus 
        //         placeholder="Answer here"/></div>
        //     <div>
        //         <SpaceBetween direction='vertical'>
        //             <SpaceBetween direction='horizontal' size='m'>
        //                 <Button variant="primary" onClick={props.handleGenerate}>Generate</Button>
        //                 <Button variant="normal" onClick={props.handleReset}>Reset</Button>
        //             </SpaceBetween>
        //         {/* <ProgressBar
        //             value={props.currentState.currentProgress}
        //             additionalInfo={props.currentState.currentProgressMsg}
        //             /> */}
        //         </SpaceBetween>
        //     </div>
        //     </Grid>}
        >
        <div id="gmessages" style={{ height: 400 + 'px', overflow: 'auto', padding: 0 + 'px' }} onScroll={props.handleScroll}>
            <SpaceBetween direction='vertical' size='xxs'>
                {props.messages?.map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
            </SpaceBetween>
        </div>
    </Container>
    );
}

const VChatPanel = props => {
    return (
        <Container 
        // footer={
        //     <Grid gridDefinition={[
        //         { colspan: 9 }, { colspan: 3 },
        //     ]}>
        //     <div>
        //         <Textarea onChange={props.onInputChange.bind(this, 'gCurrentAnswer')} 
        //         value={props.currentState.gCurrentAnswer} rows={4} autoFocus 
        //         placeholder="Answer here"/></div>
        //     <div>
        //         <SpaceBetween direction='vertical'>
        //             <SpaceBetween direction='horizontal' size='m'>
        //                 <Button variant="primary" onClick={props.handleGenerate}>Generate</Button>
        //                 <Button variant="normal" onClick={props.handleReset}>Reset</Button>
        //             </SpaceBetween>
        //         {/* <ProgressBar
        //             value={props.currentState.currentProgress}
        //             additionalInfo={props.currentState.currentProgressMsg}
        //             /> */}
        //         </SpaceBetween>
        //     </div>
        //     </Grid>}
        >
        <div id="vmessages" style={{ height: 400 + 'px', overflow: 'auto', padding: 0 + 'px' }} onScroll={props.handleScroll}>
            <SpaceBetween direction='vertical' size='xxs'>
                {props.messages?.map((message,index) => {
                    return <Message message={message} key={index}/>
                })}
            </SpaceBetween>
        </div>
    </Container>
    );
}

const LogPanel = props => {
    return (
        <Container>
        <SpaceBetween size='s'>
        {props.currentState.logs?.map((log,index) => {
            return(
            <TextContent key={index}>{log.log}</TextContent>
            );
        })}
        </SpaceBetween>
        </Container>
    );
}

const TablePanel = props => {

    return (
        <Table key={props.label}
            columnDefinitions={[
                {
                    id: "title",
                    // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    // cell: item => item.metadata['title'] || "-",
                        cell: item => <ExpandableSection headerText={item.metadata['title']}>
                            <Box variant='code'>{item.page_content}</Box>
                            </ExpandableSection>
                },
                {
                    id: "publish_date",
                    header: "Publish Date",
                    // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    // cell: item => item.metadata['title'] || "-",
                        cell: item => <Box variant='code'>{item.metadata['publish_date']}</Box>
                },
                {
                    id: "actilinkons",
                    header: "Link",
                    cell: item => (
                      <Link external href={item.metadata['url']}>
                        View
                      </Link>
                    )
                }
            ]}
            variant="container"
            items={props.results}
            loadingText="Loading data.."
            sortingDisabled
            header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
        />
    );
};

const LearnSomethingPanel = props => {

    return (
        <ExtendingPanel label="Learn Something new">
            <Table key={props.label}
                columnDefinitions={[
                    {
                        id: "title",
                        // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                        // cell: item => item.metadata['title'] || "-",
                        cell: item => <Link variant='info'>{item.q}</Link>,    
                    },
                    // {
                    //     id: "actilinkons",
                    //     header: "Link",
                    //     cell: item => (
                    //       <Link external href={item.metadata['url']}>
                    //         View
                    //       </Link>
                    //     )
                    // }
                ]}
                variant="container"
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
                wrapLines
                header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
            />
            </ExtendingPanel>
    );
};

const RelatedQuestionsPanel = props => {

    return (
        <ExtendingPanel label="Related questions">
            <form onSubmit={props.handleClick}>

            <Table key={props.label}
                columnDefinitions={[
                    {
                        id: "title",
                        // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                        // cell: item => item.metadata['title'] || "-",
                        cell: item => <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="Copy failed"
                            copySuccessText="Question copied. Paste it in above text box."
                            textToCopy={item.page_content}
                            variant="inline"
                        />
                    },
                    // {
                    //     id: "actilinkons",
                    //     header: "Link",
                    //     cell: item => (
                    //       <Link external href={item.metadata['url']}>
                    //         View
                    //       </Link>
                    //     )
                    // }
                ]}
                variant="container"
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
                wrapLines
                // header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index}
            />
            </form>
        </ExtendingPanel>
    );
};

const Loading = props => {
    if (props.results.length == 0)
        return "loading"
    else
        return ""
}
const TableInfo = props => {
    return (
        <Header variant="h5" info={
            <Link variant="info" onFollow={() => props.replaceToolsContent(props.index)}>
                Info
            </Link>}>{props.label}</Header>
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>{demoModule}</h3>}
    >
        <p>
        Find content across blogs, workshops, Immersion Days, YouTube, Cloud@Edge
        </p>
    </HelpPanel>,
    <HelpPanel header={<h2>Question</h2>}>
        Find content across blogs, workshops, Immersion Days, YouTube, Cloud@Edge
    </HelpPanel>,
    <HelpPanel header={<h2>Language</h2>}>
        Enter your question in selected local language selected and it will be translated 
        to 'English' and semantically searched. Note, the results are show in 'English'.
    </HelpPanel>,

];
import React,{ useState } from 'react';
import TopPanel from './TopPanel.jsx';

import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  SideNavigation,
  Box,
  Badge,
} from '@cloudscape-design/components';
import AnchorNavigation from "@cloudscape-design/components/anchor-navigation";
import DataProvider from '../resources/data-provider';
import {TREND_ANALYSIS_ENDPOINT} from '../resources/prod-env.jsx';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

// Component ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
function ServiceNavigation(props) {
  // If the provided link is empty, do not redirect pages
  const navigate = useNavigate();
  let location = useLocation();
  let dataProvider = new DataProvider();
  const [access] = useState(() => {
    if(props?.user){
      let user = props.user;
      let key = user.keyPrefix+"."+user.username+".accessToken";
      // console.log(key);
      let authKey = user.storage[key];
      let url=`/checkaccess?token=${authKey}`;
      let result = dataProvider.getAPIData(url,response => {
        if(response['log'] == "You are authorized!")
        return true;
        else
        return false;
      });
      return result;
  }});

  // let checkAccess = async() => {
  //   if(props?.user){
  //     let user = props.user;
  //     let key = user.keyPrefix+"."+user.username+".accessToken";
  //     // console.log(key);
  //     let authKey = user.storage[key];
  //     let url=`/checkaccess?token=${authKey}`;
  //     dataProvider.getAPIData(url,response => {
  //       if(response['log'] == "You are authorized!")
  //       return true;
  //       else
  //       return false;
  //     });
  // }
  // };

  // let access = checkRestrictedAccess(props?.user).then(resp => {return resp});
  console.log("Access "+access);

  // async function checkRestrictedAccess(user){
  //   console.log("In checkRestrictedAccess "+user);
  //           // console.log(user);
  //   if(user){
  //       let key = user.keyPrefix+"."+user.username+".accessToken";
  //       // console.log(key);
  //       let authKey = user.storage[key];
  //       let url=`/checkaccess?token=${authKey}`;
  //       let response = await new Promise(resolve => {
  //         dataProvider.getAPIData(url,resolve);
  //       });
  //       console.log("Response ",response);
  //       if(response['log'] == "You are authorized!")
  //       return true;
  //       else
  //       return false;
  //   }
  //   return false;
  // }

  function onFollowHandler(ev) {
    ev.preventDefault();
    // console.log(ev);
    navigate(ev.detail.href);
    // props.navigate(ev.detail.href);
    if (ev.detail.href == "/wafcaptcha") {
      location.reload();
    }
  }

  return (
    <React.Fragment>
      <SideNavigation
        // header={{ href: "/", text: <Box variant='h5'>Edge Services Demos</Box> }}
        items={items}
        onFollow={onFollowHandler}
      />
      {access &&     <>       <hr />
 <SideNavigation
        items={itemsRestricted}
        onFollow={onFollowHandler}
      /></>}
      <hr />
      <SideNavigation
        items={itemsOthers}
        onFollow={onFollowHandler}
      />
    </React.Fragment>
  );
}

const items1 = [
  {
    text: "CloudFront",
    level: 1
  },
  {  text: "Acceleration", 
     href: '/acceleration', 
     level: 2
  },
  {  text: "Signed URLs", 
     href: '/signedurl', 
     level: 2
  }
];

const items = [
  {
    type: 'section',
    text: <Box variant='strong'>CloudFront</Box>,
    items: [
      { type: 'link', text: <Box>Acceleration</Box>, href: '/acceleration' },
      { type: 'link', text: <Box>Signed URLs</Box>, href: '/signedurl' },
      { type: 'link', text: <Box>CMCD</Box>, href: '/cmcd' },
      { type: 'link', text: <Box>Caching</Box>, href: '/caching' },
      { type: 'link', text: <Box>Image Optimization</Box>, href: '/image' },
    ]
  },
  {
    type: 'section',
    text: <Box variant='strong'>Edge Functions</Box>,
    items: [
      { type: 'link', text: <Box>Inspect Request <Badge color='grey'>maintenance</Badge></Box>, href: '/inspect' }
    ]
  },
  {
    type: 'section',
    text: <Box variant='strong'>Observability</Box>,
    items: [
      {
        type: 'link', text: <Box>Multi-CDN <Badge color='blue'>beta</Badge></Box>, href: '/multicdn'
      },
      {
        type: 'link', text: <Box>Website Performance <Badge color='blue'>beta</Badge></Box>, href: '/cfrum'
      },
      { type: 'link', text: <Box>WAF Logs</Box>, href: '/wafdashboard' },

    ]
  },
  {
    type: 'section',
    text: <Box variant='strong'>WAF</Box>,
    items: [
      {
        type: 'link', text: <Box>SQLi/XSS</Box>, href: '/wafsqli'
      },
      {
        type: 'link', text: <Box>RBR</Box>, href: '/wafrbr'
      },
      {
        type: 'link', text: <Box>ATP</Box>, href: '/wafatp'
      },
      { type: 'link', text: <Box>CAPTCHA</Box>, href: '/wafcaptcha' },
      {
        type: 'link', text: <Box>Custom Request/Response <Badge color='green'>new</Badge></Box>, href: '/wafcustom'
      },
    ]
  },
  {
    type: 'section',
    text: <Box variant='strong'>Video</Box>,
    items: [
      { type: 'link', text: <Box>Video Comparitor</Box>, href: '/videocomparitor' },
      // { type: 'link', text: <Box>Channel Selector < sup className='text_color_red' > <i>BETA</i></sup></Box>, href: '/simplechannelselector' },
    ]
  },
  {
    type: 'section',
    text: <Box variant='strong'>Tools</Box>,
    items: [
      { type: 'link', text: <Box>CDN Finder</Box>, href: '/cdnfinder' },
      // { type: 'link', text: <Box>CDN Finder <Badge color='red'>maintenance</Badge></Box>, href: '/cdnfinder' },
      { type: 'link', text: <Box>Discover Content (Edge)</Box>, href: '/contentdiscovery' },
      { type: 'link', text: <Box>Discover Content (Media)</Box>, href: '/mediacontentdiscovery' },
      { type: 'link', text: <Box>Check Idea </Box>, href: '/checkidea' },
      { type: 'link', text: <Box>Edge Configurator <Badge color='blue'>experimental</Badge></Box>, href: '/edgeconfigurator' },
      // { type: 'link', text: <Box>Spec Req <Badge color='blue'>restricted</Badge></Box>, href: '/specreq' },
    ]
  },
];

const itemsRestricted = [
  {
    type: 'link',
    text: <Box>Trends - SpecReq<Badge color='red'>restricted</Badge></Box>,
    href: '/specreq'
  },
]

const itemsOthers = [
  {
    type: 'link',
    text: 'Resources',
    href: '/resources'
  },
  {
    type: 'link',
    text: 'Feedback',
    href: '/feedback'
  },
  {
    type: 'link',
    text: <Box>Usage Stats</Box>,
    href: '/usagestats'
  },
  {
    type: 'link',
    text: 'Sign-In/Sign-out',
    href: '/login'
  }
];
export default withRouter(ServiceNavigation);

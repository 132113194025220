import React, { useReducer, Suspense, lazy } from 'react';
import GlobalState, { reducer } from "./Global";
// import { Redirect } from 'react-router';
import { Route, Routes, useLocation, useSearchParams, Navigate } from 'react-router-dom';
// import SignedURL from './signedurl/SignedURL.jsx';
// import Acceleration from './acceleration/Acceleration1.jsx';
import AGAcceleration from './agacceleration/AGAAcceleration.jsx';
import CMCD from './cmcd/CMCD.jsx';
import Caching from './caching/Caching.jsx';
import ServiceHomepage from './ServiceHomepage.jsx';
import Login from './Login.jsx';
import useAuth from './useAuth.js';
import LambdaAtEdge from './cf/LambdaAtEdge.jsx';
import ImageOptimization from './imageoptimization/Image.jsx';
import Inspect from './inspectheaders/Inspect';
import WIP from './WIP.jsx';
import Feedback from './feedback/Feedback.jsx';
import WAFDashboard from './wafdashboards/WAFDashboard';
import CDNFinder from './cdnfinder/cdnfinder';
import ContentDiscovery from './contentdiscovery/contentdiscovery';
import MediaContentDiscovery from './mediacontentdiscovery/contentdiscovery';
import EdgeConfigurator from './edgeconfigurator/edgeconfig';
import SpecReq from './trend_analysis/specreq';

import CheckIdea from './contentdiscovery/checkidea';
import WAFCommonFeatures from './wafcommonfeatures/sqlixss';
import WAFATP from './wafcommonfeatures/atp';
import MultiCDN from './observability/MultiCDN';
import CloudFrontRUM from './observability/CloudFrontRUM';
import WAFRBR from './wafcommonfeatures/rbr';
import WAFCustom from './wafcommonfeatures/custom';
import WAFCaptcha from './wafcommonfeatures/captcha';
import { sendPageView } from '../resources/rum-provider.ts';
import DemoPortalUsage from './observability/DemoPortalUsage';
import '@cloudscape-design/global-styles/index.css';
// import VideoComparitor from './videocomparitor/index';
const Acceleration = lazy(() => import('./acceleration/Acceleration1.jsx'));
const SignedURL = lazy(() => import('./signedurl/SignedURL.jsx'));
const SimpleChannelSelector = lazy(() => import('./simplechannelselector/index'));
const VideoComparitor = lazy(() => import('./videocomparitor/index.jsx'));
const Resources = lazy(() => import('./resources/template.jsx'));
// function cwr(operation, payload) { }
// Class App is the "output" generated on every build,
// it is what you will see on the webpage.

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [state, dispatch] = useReducer(reducer, initialState);

  // new AppConfig();

  let routeto = searchParams.get("routeto");
  // console.log("QS :%s", routeto);

  let location = useLocation();
  location.path = routeto;
  React.useEffect(() => {
    console.log("Location :", location.pathname);
    sendPageView(location.pathname);
  }, [location]);

  const user = useAuth();
  // console.log(user)
  // window.location.hostname != "localhost" &&
  if (window.location.hostname != "localhost" && window.location.hostname != "ganeshji.people.aws.dev" && !user) {
    // if (!user) {
    localStorage.setItem("lastUrl", location.pathname);
    return (<Login />)
  }
  else if(localStorage.getItem("lastUrl")){
    let lastUrl = localStorage.getItem("lastUrl");
    console.log("Going to last requested page :", lastUrl);
    localStorage.removeItem("lastUrl");
    return (<Navigate to={lastUrl} />)
  }
  else if (routeto) {
    // console.log("app user is :", user.expiryTTL);
    // let camelCaseName = routeto.substring(0, 1).toUpperCase + routeto.substring(1, routeto.length);
    return (<Navigate to={routeto} />)
  }
  else {
    // console.log("app user is1 :", user);
    // console.log("in main app and the user is");
    // console.log(user)
  }

  if (user?.attributes?.identities) {
    let userId = user.attributes.identities;
    console.log("app user TTL :", user.expiryTTL);
    let userIdObj = JSON.parse(userId);
    // console.log("user id obj :%j", userIdObj[0].userId);
    localStorage.setItem("userId", userIdObj[0].userId);
    localStorage.setItem("userProfile",user.attributes.profile);
  }

  return (
    // <div>test</div>
    // When you create a new file or template, add it below
    // as a new 'Route' so you can link to it with a url.
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<ServiceHomepage user={user}/>} />
        <Route path="/signedurl" element={<SignedURL />} />
        <Route path="/acceleration" element={<Acceleration />} />
        <Route path="/caching" element={<Caching />} />
        <Route path="/image" element={<ImageOptimization />} />
        <Route path="/cmcd" element={<CMCD />} />
        <Route path="/login" element={<Login />} />
        <Route path="/waf" element={<WIP />} />
        <Route path="/agacceleration" element={<WIP />} />
        <Route path="/inspect" element={<Inspect />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/wafdashboard" element={<WAFDashboard />} />
        <Route path="/cdnfinder" element={<CDNFinder />} />
        <Route path="/contentdiscovery" element={<ContentDiscovery user={user}/>} />
        <Route path="/mediacontentdiscovery" element={<MediaContentDiscovery user={user}/>} />
        <Route path="/edgeconfigurator" element={<EdgeConfigurator user={user}/>} />
        <Route path="/specreq" element={<SpecReq user={user}/>} />
        <Route path="/checkidea" element={<CheckIdea user={user}/>} />
        <Route path="/wafsqli" element={<WAFCommonFeatures />} />
        <Route path="/wafatp" element={<WAFATP />} />
        <Route path="/multicdn" element={<MultiCDN />} />
        <Route path="/cfrum" element={<CloudFrontRUM />} />
        <Route path="/wafrbr" element={<WAFRBR />} />
        <Route path="/wafcustom" element={<WAFCustom />} />
        <Route path="/wafcaptcha" element={<WAFCaptcha />} />
        <Route path="/simplechannelselector" element={<SimpleChannelSelector />} />
        <Route path="/videocomparitor" element={<VideoComparitor />} />
        <Route path="/usagestats" element={<DemoPortalUsage />} />
        <Route path="/resources" element={<Resources />} />
      </Routes>
    </Suspense>
  );
}

const initialState = {
  navigationState: false
};

function AppConfig(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalState initialState={state} dispatch={dispatch}>
    </GlobalState>
  );
}

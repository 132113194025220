import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import BrowserCheck from '../BrowserCheck';
import TopPanel from '../TopPanel';
import { Buffer } from "buffer";
import discoveryImg from './images/content_inventory_discovery_workflow.png';
import ingestImg from './images/content_inventory_ingestion_workflow.png';

import {
    CDN_FINDER_ENDPOINT,
} from '../../resources/prod-env.jsx';
import {
    AppLayout,
    Button,
    ColumnLayout,
    Form,
    Table,
    Header,
    SpaceBetween,
    Container,
    Link,
    HelpPanel,
    Box,
    Alert,
    Input,
    StatusIndicator,
    ExpandableSection,
    FormField,
    Select,
    CopyToClipboard,
    Grid,
} from '@cloudscape-design/components';
import typescriptHighlight from "@cloudscape-design/code-view/highlight/typescript";
import * as CodeView from "@cloudscape-design/code-view";

import '../../styles/form.scss';
import SynopsisPanelV2 from '../SynopsisPanelV2';
import { sendAnalytics, sendError } from '../../resources/rum-provider';
const demoModule = "ContentDiscovery";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class ContentDiscovery extends React.Component {

    constructor(props) {
        super(props);
        // console.log(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onFollowQuestion = this.onFollowQuestion.bind(this);
        this.dataProvider = new DataProvider();

        this.state = {
            toolsIndex: 0, toolsOpen: false, alert: false,
            q:"what are the best practices for static delivery?",
            status: "takes about a minute",
            statusType: "info",
            contentTypes: [],
            k:{label: "20",value: "20" },
            lang:{label: "English",value: "en" },
            authKey:'',
            userId:'',
            // user:props.user,
        };
        let user = props.user;
        // console.log(user);
        if(user){
            let key = user.keyPrefix+"."+user.username+".accessToken";
            // console.log(key);
            let authKey = user.storage[key];
            this.state.authKey = authKey;
            this.state.userId = user.storage['userId'];
            // console.log(authKey);
        }
    }

    onInputChange(name, e) {
        e.preventDefault();
        let value = null;
        if(name === 'q'){
            value = e.detail.value;
            console.log("In onInputChange %s %j", name, e.detail.selectedOption);
        }
        else{
            value = e.detail.selectedOption;
        }
        this.setState({ [name]: value});
    }

    onFollowQuestion(e){
        console.log("In onFollowQuestion ",e);
        // this.onInputChange(e);
        // this.handleClick(e);
    }

    handleClick(e) {
        console.log("In handleClick ",e);

        e.preventDefault();
        if (!this.state.q) {
            // console.log("Region not selected");
            this.setState({ alert: true });
            return;
        }
        else {
            this.handleAlert();
        }

        sendAnalytics({ demo: demoModule });
        let q = this.state.q;

        this.setState({
            status: "in progress, may take upto a minute..",
            statusType: "loading"
        });

        // let user = localStorage.getItem("user");
        // if(user){
        //     let key = user.keyPrefix+"."+user.username+".accessToken";
        //     console.log("Set from handleclick",key);
        //     let authKey = user.storage[key];
        //     this.setState({"authKey":authKey,"userId":user.storage['userId']});
        //     // console.log(authKey);
        // }

        let userId = localStorage.getItem("userId");

        let payload = { 
            "q": q,
            "k":this.state.k.value,
            "Authorization":this.state.authKey,
            "u":userId,
            "l":this.state.lang.value
        };

        // console.log(this.state.k);
        if(this.state.contentTypes){
            this.state.contentTypes.forEach(segment => {
                delete this.state[[`${segment}`]];
            });
        }
  
        this.dataProvider.search(payload,response => {
            console.log("Response1 :", response);

            let docs = response.docs;
            let relatedDocs = response['related_questions'];
            this.setState({'relatedDocs':relatedDocs});
            this.setState({'translatedQ':response.question});
            let segregateDocs = {}

            for(let i=0;i<docs.length;i++){
                let ct = docs[i].metadata['type'];
                if(!(ct in segregateDocs)){
                    console.log(ct);
                    segregateDocs[ct] = [];
                }
                segregateDocs[ct].push(docs[i]);
            }
            // console.log(segregateDocs);
            for (const [segment, value] of Object.entries(segregateDocs)) {
                let sortedList = value.sort(
                    (p1, p2) => (p1.metadata['publish_date'] < p2.metadata['publish_date']) ? 1 : (p1.metadata['publish_date'] > p2.metadata['publish_date']) ? -1 : 0);
                this.state[[`${segment}`]] = sortedList;
            }

            this.setState({
                status: "completed",
                statusType: "info"
            });
        });

        // this.state.segments.forEach(segment => {
        //     this.state[[`${segment}`]] = [];
        //     promises.push(new Promise((resolve, reject) => this.fetchData(segment, q, resolve)));
        // });

        // Promise.all(promises).then((values) => {
        //     console.log(values);
        //     this.setState({
        //         status: "completed",
        //         statusType: "info"
        //     });
        // });
    }

    handleAlert() {
        // console.log("In handleAlert");
        this.setState({ alert: false });
    }
    componentDidCatch(error, info) {
        console.log(error);
        sendError(error);
        // cwr('recordError', error);
    };

    componentDidMount() {
        // this.dataProvider.getData('images', response => this.setState({ images: response }));
        this.dataProvider.listContentTypes({"Authorization":this.state.authKey}, response => this.setState({ contentTypes: response }));
        this.dataProvider.listQuestions({"Authorization":this.state.authKey}, response => this.setState({ questions: response }));
    }

    render() {
        return (
            <React.Fragment>
                <TopPanel label={demoModule} />
                <AppLayout
                    navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                    // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
                    content={
                        <Content
                            // Changes the Help panel content when the user clicks an 'info' link
                            replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
                            handleClick={this.handleClick}
                            handleAlert={this.handleAlert}
                            onInputChange={this.onInputChange}
                            onFollowQuestion={this.onFollowQuestion}
                            currentState={this.state}
                        />
                    }
                    contentType="default"
                    tools={Tools[this.state.toolsIndex]}
                    onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
                    toolsOpen={this.state.toolsOpen}
                    navigationOpen={this.state?.navigationState}
                    onNavigationChange={e => this.setState({ navigationState: e.detail.open })}
                />
            </React.Fragment>
        );
    }
};

const Content = props => {

    return (
        <React.Fragment>
            <BrowserCheck />
            <SpaceBetween direction='vertical' size='l'>
                <SynopsisPanelV2 label="Synopsis" architectureImgs={[ingestImg,discoveryImg]} videolink="https://broadcast.amazon.com/videos/1290218?seek=00h33m03s">
                    An application of GenAI, this tool helps find content across our various content sources including blogs, workshops, Immersion Days, YouTube and Cloud@Edge.
                </SynopsisPanelV2>
                <Container>
                    <form onSubmit={props.handleClick}>
                        <Form 
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Alert type='error'
                                        visible={props.currentState.alert}
                                        onDismiss={() => props.handleAlert()}
                                        // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                                        dismissible
                                    // header={<Box variant='p'>Select AWS Region</Box>}
                                    >
                                        <Box variant='small'>Enter your question and hit 'Find'</Box>
                                    </Alert>
                                    <StatusIndicator type={props.currentState.statusType}>
                                        {props.currentState.status}
                                    </StatusIndicator>
                                    <Select 
                                        selectedOption={props.currentState?.k}
                                        onChange={props.onInputChange.bind(this, 'k')}
                                        options={[
                                            { label: "20", value: "20" },
                                            { label: "40", value: "40" },
                                            { label: "60", value: "60" },
                                            { label: "80", value: "80" },
                                            { label: "100", value: "100" },      
                                        ]}
                                        selectedAriaLabel="Selected"
                                    />
                                    <Button variant="primary">Find</Button>
                                </SpaceBetween>
                            }>
                            <Grid
                                gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
                            <FormField label="Language" info={
                                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                                    Info
                                </Link>
                            }>
                                <Select
                                    selectedOption={props.currentState?.lang}
                                    onChange={props.onInputChange.bind(this, 'lang')}
                                    options={[
                                        { label: "English", value: "en" },
                                        { label: "Portuguese", value: "pt" },
                                    ]}
                                    selectedAriaLabel="Selected"
                                />
                            </FormField>
                            <FormField label="Type in your question" stretch info={
                                <Link variant="info" onFollow={() => props.replaceToolsContent(1)}>
                                    Info
                                </Link>
                            }>
                                <Input inputMode='search' 
                                    onChange={props.onInputChange.bind(this, 'q')}
                                    value={props.currentState.q}
                                    type="search"
                                    placeholder="Find content across blogs, workshops, Immersion Days, Youtube, Cloud@Edge"
                                />
                            </FormField>  
                            </Grid>                 
                            {/* <FormField label="results" info={
                                <Link variant="info" onFollow={() => props.replaceToolsContent(4)}>
                                    Info
                                </Link>
                            }>
                                
                            </FormField> */}
                        </Form>
                    </form>
                    </Container>
                <SpaceBetween direction='vertical' size='l'>
                    {props.currentState.lang.value != 'en' && <Box color='text-label'>Translated Question: {props.currentState.translatedQ}</Box>}
                    {props.currentState.Blog && <TablePanel label="Blog" results={props.currentState.Blog} replaceToolsContent={props.replaceToolsContent} index="1" />}
                    {props.currentState.YouTube && <TablePanel label="YouTube" results={props.currentState.YouTube} replaceToolsContent={props.replaceToolsContent} index="7" />}
                    {props.currentState['Case Study'] && <TablePanel label="Case Study" results={props.currentState['Case Study']} replaceToolsContent={props.replaceToolsContent} index="8" />}
                    {props.currentState.Workshop && <TablePanel label="Workshop" results={props.currentState.Workshop} replaceToolsContent={props.replaceToolsContent} index="9" />}
                    {props.currentState['Immersion Day'] && <TablePanel label="Immersion Day" results={props.currentState['Immersion Day']} replaceToolsContent={props.replaceToolsContent} index="10" />}
                    {props.currentState['Technical Guidance'] && <TablePanel label="Technical Guidance" results={props.currentState['Technical Guidance']} replaceToolsContent={props.replaceToolsContent} index="10" />}
                    {props.currentState.Whitepaper && <TablePanel label="Whitepaper" results={props.currentState.Whitepaper} replaceToolsContent={props.replaceToolsContent} index="10" />}
                    {props.currentState.Documentation && <TablePanel label="Documentation" results={props.currentState.Documentation} replaceToolsContent={props.replaceToolsContent} index="10" />}                    
                    {/* {props.currentState.secondaryvideos && <TablePanel label="Video" results={props.currentState.secondaryvideos} replaceToolsContent={props.replaceToolsContent} index="11" />} */}
                </SpaceBetween>
                {props.currentState.relatedDocs && <RelatedQuestionsPanel label="Learn" results={props.currentState.relatedDocs}  handleClick={props.handleClick}/>}
                {/* {props.currentState.questions && <LearnSomethingPanel label="Learn" results={props.currentState.questions}/>} */}
                <ExtendingPanel label="Extend it">
                    <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
                        <Box>Questions/ideas on this tool?</Box>
                        <SpaceBetween direction='vertical'>
                            <Box variant='strong'>
                                Jaiganesh Girinathan,
                                ganeshji@amazon.com
                            </Box>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExtendingPanel>
            </SpaceBetween>
        </React.Fragment >
    );
};

const TablePanel = props => {

    return (
        <Table key={props.label}
            columnDefinitions={[
                {
                    id: "title",
                    // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    // cell: item => item.metadata['title'] || "-",
                        cell: item => <ExpandableSection headerText={item.metadata['title']}>
                            <Box variant='code'>{item.page_content}</Box>
                            </ExpandableSection>
                },
                {
                    id: "publish_date",
                    header: "Publish Date",
                    // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                    // cell: item => item.metadata['title'] || "-",
                        cell: item => <Box variant='code'>{item.metadata['publish_date']}</Box>
                },
                {
                    id: "actilinkons",
                    header: "Link",
                    cell: item => (
                      <Link external href={item.metadata['url']}>
                        View
                      </Link>
                    )
                }
            ]}
            variant="container"
            items={props.results}
            loadingText="Loading data.."
            sortingDisabled
            header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
        />
    );
};

const LearnSomethingPanel = props => {

    return (
        <ExtendingPanel label="Learn Something new">
            <Table key={props.label}
                columnDefinitions={[
                    {
                        id: "title",
                        // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                        // cell: item => item.metadata['title'] || "-",
                        cell: item => <Link variant='info'>{item.q}</Link>,    
                    },
                    // {
                    //     id: "actilinkons",
                    //     header: "Link",
                    //     cell: item => (
                    //       <Link external href={item.metadata['url']}>
                    //         View
                    //       </Link>
                    //     )
                    // }
                ]}
                variant="container"
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
                wrapLines
                header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index} />
            />
            </ExtendingPanel>
    );
};

const RelatedQuestionsPanel = props => {

    return (
        <ExtendingPanel label="Related questions">
            <form onSubmit={props.handleClick}>

            <Table key={props.label}
                columnDefinitions={[
                    {
                        id: "title",
                        // header: <TableInfo label="Title" index="1" replaceToolsContent={props.replaceToolsContent} />,
                        // cell: item => item.metadata['title'] || "-",
                        cell: item => <CopyToClipboard
                            copyButtonText="Copy"
                            copyErrorText="Copy failed"
                            copySuccessText="Question copied. Paste it in above text box."
                            textToCopy={item.page_content}
                            variant="inline"
                        />
                    },
                    // {
                    //     id: "actilinkons",
                    //     header: "Link",
                    //     cell: item => (
                    //       <Link external href={item.metadata['url']}>
                    //         View
                    //       </Link>
                    //     )
                    // }
                ]}
                variant="container"
                items={props.results}
                loadingText="Loading data.."
                sortingDisabled
                wrapLines
                // header=<TableInfo label={props.label} replaceToolsContent={props.replaceToolsContent} index={props.index}
            />
            </form>
        </ExtendingPanel>
    );
};

const Loading = props => {
    if (props.results.length == 0)
        return "loading"
    else
        return ""
}
const TableInfo = props => {
    return (
        <Header variant="h5" info={
            <Link variant="info" onFollow={() => props.replaceToolsContent(props.index)}>
                Info
            </Link>}>{props.label}</Header>
    );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h3>{demoModule}</h3>}
    >
        <p>
        Find content across blogs, workshops, Immersion Days, YouTube, Cloud@Edge
        </p>
    </HelpPanel>,
    <HelpPanel header={<h2>Question</h2>}>
        Find content across blogs, workshops, Immersion Days, YouTube, Cloud@Edge
    </HelpPanel>,
    <HelpPanel header={<h2>Language</h2>}>
        Enter your question in selected local language selected and it will be translated 
        to 'English' and semantically searched. Note, the results are show in 'English'.
    </HelpPanel>,

];